import { FC } from 'react';
import IconProps from './IconProps';

const CloudTransferIcon: FC<IconProps> = (props) => {
  const { onClick, className, gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    <svg className={`inline ${cursor} ${className}`} viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4722 14.4236C11.2758 14.5414 11.0402 14.4629 10.9223 14.3058C10.8438 14.1094 10.8831 13.8738 11.0794 13.7559C11.3544 13.5988 11.6685 13.481 11.9827 13.4025C11.4329 12.0279 10.0976 11.0853 8.60516 11.0853C6.75927 11.0853 5.22758 12.4599 4.99193 14.2665C5.03121 14.5414 4.83483 14.7378 4.55992 14.6985C3.10677 14.5021 1.77145 15.5233 1.77145 17.055C1.77145 18.351 2.83185 19.4114 4.16717 19.4114H12.7682C14.2214 19.4114 15.3996 18.2332 15.3996 16.78C15.3996 15.2876 14.2214 14.1094 12.7682 14.1094C12.2969 14.1094 11.8649 14.2272 11.4722 14.4236ZM12.7682 13.3239C14.6534 13.3239 16.1851 14.8556 16.1851 16.78C16.1851 18.6652 14.6534 20.1969 12.7682 20.1969H4.16717C2.39983 20.1969 0.985962 18.783 0.985962 17.055C0.985962 15.2091 2.43911 13.8738 4.285 13.8738C4.63846 11.7922 6.48435 10.2998 8.60516 10.2998C10.4903 10.2998 12.1791 11.5173 12.7682 13.3239Z"
        fill="url(#paint0_linear_4183_66126)"
        stroke="url(#paint1_linear_4183_66126)"
        strokeWidth="0.711305"
      />
      <path
        d="M12.5452 25.8651C8.99062 27.8116 4.5533 26.8199 2.15016 23.6161C1.86713 23.2388 2.00447 22.7107 2.41113 22.4717C2.81334 22.2354 3.32678 22.3725 3.61644 22.7382C5.5072 25.1253 8.86881 25.8572 11.6089 24.4457L10.3254 24.1588C9.87244 24.0682 9.57043 23.6151 9.66103 23.1621C9.75163 22.7091 10.2046 22.4071 10.6576 22.4977L13.9193 23.1621C14.1005 23.1923 14.2364 23.2678 14.3572 23.3886C14.5082 23.5396 14.6139 23.736 14.6139 23.9474L14.6139 23.9776L14.6139 24.0682C14.6139 24.0984 14.5988 24.1437 14.5988 24.1739L13.9495 27.4204C13.8589 27.8734 13.4059 28.1754 12.9529 28.0848C12.4999 27.9942 12.1979 27.5412 12.2885 27.0882L12.5452 25.8651Z"
        fill="url(#paint2_linear_4183_66126)"
      />
      <path
        d="M3.44998 6.17512C6.37959 3.37494 10.9224 3.1844 14.0729 5.65705C14.4439 5.94827 14.4479 6.49387 14.117 6.82995C13.7896 7.16235 13.2582 7.16281 12.8838 6.88454C10.4396 5.06808 7.00308 5.23117 4.72166 7.30386L6.03569 7.24879C6.4967 7.21906 6.90566 7.57846 6.9354 8.03948C6.96513 8.50049 6.60572 8.90945 6.14471 8.93918L2.82226 9.14159C2.63941 9.15932 2.4886 9.12156 2.34065 9.03614C2.15571 8.92937 2.00281 8.76711 1.94809 8.56291L1.94028 8.53374L1.91683 8.44623C1.90901 8.41706 1.91187 8.36939 1.90406 8.34022L1.69097 5.03627C1.66124 4.57525 2.02064 4.16629 2.48166 4.13656C2.94268 4.10683 3.35164 4.46623 3.38137 4.92725L3.44998 6.17512Z"
        fill="url(#paint3_linear_4183_66126)"
      />
      <defs>
        <linearGradient id="paint0_linear_4183_66126" x1="0.985962" y1="13.3218" x2="13.5484" y2="21.5689" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint1_linear_4183_66126" x1="0.985962" y1="13.3218" x2="13.5484" y2="21.5689" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint2_linear_4183_66126" x1="11.9091" y1="29.6583" x2="8.73831" y2="17.7766" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint3_linear_4183_66126" x1="3.08259" y1="2.34657" x2="9.22056" y2="13.0027" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
      </defs>
    </svg>;
  }

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4722 14.4236C11.2758 14.5414 11.0402 14.4629 10.9223 14.3058C10.8438 14.1094 10.8831 13.8738 11.0794 13.7559C11.3544 13.5988 11.6685 13.481 11.9827 13.4025C11.4329 12.0279 10.0976 11.0853 8.60516 11.0853C6.75927 11.0853 5.22758 12.4599 4.99193 14.2665C5.03121 14.5414 4.83483 14.7378 4.55992 14.6985C3.10677 14.5021 1.77145 15.5233 1.77145 17.055C1.77145 18.351 2.83185 19.4114 4.16717 19.4114H12.7682C14.2214 19.4114 15.3996 18.2332 15.3996 16.78C15.3996 15.2876 14.2214 14.1094 12.7682 14.1094C12.2969 14.1094 11.8649 14.2272 11.4722 14.4236ZM12.7682 13.3239C14.6534 13.3239 16.1851 14.8556 16.1851 16.78C16.1851 18.6652 14.6534 20.1969 12.7682 20.1969H4.16717C2.39983 20.1969 0.985962 18.783 0.985962 17.055C0.985962 15.2091 2.43911 13.8738 4.285 13.8738C4.63846 11.7922 6.48435 10.2998 8.60516 10.2998C10.4903 10.2998 12.1791 11.5173 12.7682 13.3239Z"
        fill="#737373"
        stroke="#737373"
        strokeWidth="0.711305"
      />
      <path
        d="M12.5452 25.8651C8.99062 27.8116 4.5533 26.8199 2.15016 23.6161C1.86713 23.2388 2.00447 22.7107 2.41113 22.4717C2.81334 22.2354 3.32678 22.3725 3.61644 22.7382C5.5072 25.1253 8.86881 25.8572 11.6089 24.4457L10.3254 24.1588C9.87244 24.0682 9.57043 23.6151 9.66103 23.1621C9.75163 22.7091 10.2046 22.4071 10.6576 22.4977L13.9193 23.1621C14.1005 23.1923 14.2364 23.2678 14.3572 23.3886C14.5082 23.5396 14.6139 23.736 14.6139 23.9474L14.6139 23.9776L14.6139 24.0682C14.6139 24.0984 14.5988 24.1437 14.5988 24.1739L13.9495 27.4204C13.8589 27.8734 13.4059 28.1754 12.9529 28.0848C12.4999 27.9942 12.1979 27.5412 12.2885 27.0882L12.5452 25.8651Z"
        fill="#C6A97D"
      />
      <path
        d="M3.44998 6.17512C6.37959 3.37494 10.9224 3.1844 14.0729 5.65705C14.4439 5.94827 14.4479 6.49387 14.117 6.82995C13.7896 7.16235 13.2582 7.16281 12.8838 6.88454C10.4396 5.06808 7.00308 5.23117 4.72166 7.30386L6.03569 7.24879C6.4967 7.21906 6.90566 7.57846 6.9354 8.03948C6.96513 8.50049 6.60572 8.90945 6.14471 8.93918L2.82226 9.14159C2.63941 9.15932 2.4886 9.12156 2.34065 9.03614C2.15571 8.92937 2.00281 8.76711 1.94809 8.56291L1.94028 8.53374L1.91683 8.44623C1.90901 8.41706 1.91187 8.36939 1.90406 8.34022L1.69097 5.03627C1.66124 4.57525 2.02064 4.16629 2.48166 4.13656C2.94268 4.10683 3.35164 4.46623 3.38137 4.92725L3.44998 6.17512Z"
        fill="#C6A97D"
      />
    </svg>
  );
};

export default CloudTransferIcon;
